











import Vue from "vue";
import Component from "vue-class-component";
import { vxm } from "@/store";
import RegisterPort from "@/views/RegisterPort.vue";
import { CCol, CImg, CRow } from "@coreui/vue";

@Component({
  components: {
    RegisterPort,
  },
})
export default class Projects extends Vue {
  private theUi = vxm.ui;

  created() {
    this.theUi.closeAside();
  }
}
